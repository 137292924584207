import React from 'react'
import styled from 'styled-components'
import { detect } from 'detect-browser'
import App from 'App'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import { COLOR_CONSTANTS, radius, fontSizes } from 'theme'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { Text, H1, H2, H3 } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'
import GetStartedComponent from 'components/GetStartedComponent'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import imageHeader from 'static/images/extensions/header_picture.webp'

import GridDropdown from '../Faq/GridDropdown'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledHeaderWrapper = styled(Wrapper)`
  overflow: hidden;
`

const StyledButtonSelectPlan = styled(Button.Gradient)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  width: 215px;
  height: ${pxToRem(44)};
  font-size: ${fontSizes.m};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const BROWSER_INFORMATION = {
  default: {
    title: 'Install the Chrome or Firefox extension!',
    titleBlue: 'Chrome or Firefox extension!',
    description:
      'Vista Social browser extensions for Chrome and Firefox allow you to share links, images, and videos from wherever you are on the web — without having to go back to your Vista Social dashboard.',
    buttonText: 'Install',
  },
  chrome: {
    title: 'Browser Extensions | Vista Social',
    titleBlue: 'Chrome extension!',
    description: 'Share links, pictures and videos from wherever you are on the web with the Vista Social Extensions',
    buttonText: 'Install on Chrome',
    link: 'https://chrome.google.com/webstore/detail/vista-social/penkaabfmokbebopkikfcoobplgbncjc',
  },
  firefox: {
    title: 'Browser Extensions | Vista Social',
    titleBlue: 'Firefox extension!',
    description: 'Share links, pictures and videos from wherever you are on the web with the Vista Social Extensions',
    buttonText: 'Install on Firefox',
    link: 'https://addons.mozilla.org/firefox/addon/vista-social/',
  },
}

const browser = detect()

const Extensions = () => {
  let pageInfo

  switch (browser && browser.name) {
    case 'chrome':
      pageInfo = BROWSER_INFORMATION.chrome
      break
    case 'firefox':
      pageInfo = BROWSER_INFORMATION.firefox
      break
    default:
      pageInfo = BROWSER_INFORMATION.default
      break
  }

  return (
    <App fullHeader>
      <SEO title={pageInfo.title} description={pageInfo.description} path="/extensions/" />

      <StyledHeaderWrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb="xl" flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              pt={{ mobile: 0, desktop: 'm' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Install the
                        <H1
                          as="span"
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          {' '}
                          {pageInfo.titleBlue}
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      {pageInfo.description}
                    </H2>
                    {pageInfo.link && (
                      <Flex
                        mt="l"
                        mb={{ mobile: 'xl', tablet: '0' }}
                        pt="m"
                        pb={{ mobile: 'm', tablet: '0' }}
                        justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                      >
                        <StyledButtonSelectPlan as="a" href={pageInfo.link} target="_blank">
                          {pageInfo.buttonText}
                        </StyledButtonSelectPlan>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                ml="auto"
                mr={{ mobile: 'auto', desktop: 0 }}
                mt={{ mobile: 'l', desktop: 0 }}
                position="relative"
              >
                <Box
                  position="absolute"
                  borderRadius={radius.pill}
                  bg={COLOR_CONSTANTS.SALT}
                  width="154px"
                  height="154px"
                  top="-25px"
                  left="-60px"
                  zIndex="-1"
                />
                <Box
                  position="absolute"
                  borderRadius={radius.pill}
                  bg={COLOR_CONSTANTS.SALT}
                  width="210px"
                  height="210px"
                  bottom="-90px"
                  right="-110px"
                  zIndex="-1"
                />
                <LocalImage src={imageHeader} width="100%" mx="auto" maxWidth="540px" alt={pageInfo.title} />
              </Flex>
            </Grid>
            <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </StyledHeaderWrapper>

      <GetStartedComponent />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.all} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default Extensions
